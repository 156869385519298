module controllers {
    export module master {

        interface IBondStoresCtrlScope extends ng.IScope {
        }

        interface IBondStoresParams extends ng.ui.IStateParamsService {
        }

        export class bondStoresCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "warehouseService",
                "$state",
                "$stateParams",
                "$transitions",
                "goodsDeclarationService"
            ];

            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.BondStoreReceiptsViewModel>;
            searchAccordian: boolean = false;
            myHook: any;


            customsWarehouse : interfaces.applicationcore.IDropdownModel;
            showAll: boolean = false;
            showAged: boolean = false;
            AssessmentDateFrom?: moment.Moment;
            AssessmentDateTo?: moment.Moment;


            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string = "Bond Stores";
            sortName: string;
            sortDirection: string;

            filterNames: string[] = ["BondStoresCallSign","BondStoresName","BondStoresOperatorCodeName","IsActive"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];
            
            constructor(private $scope: IBondStoresCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private warehouseService: interfaces.master.IWarehouseService,
                private $state: ng.ui.IStateService,
                private $stateParams: IBondStoresParams,
                private $transitions: ng.ui.core.ITransition,
                private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService
            ) {        
            }

            loadCustomsWarehouses(){
                return this.warehouseService
                .getCustomsWarehouses()
                .query({}, (resultList) => {}).$promise;
            }

            showAllChanged(){
                if (this.customsWarehouse)
                    this.DoSearch();
            }

            showAgedChanged(){
                if (this.customsWarehouse)
                    this.DoSearch();
            }
            
            DoSearch(){
            

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'bond.stores'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        if (this.AssessmentDateFrom){
                            searchObject.filters.push({ Name: 'AssessmentDateFrom', Value: this.AssessmentDateFrom });
                        }

                        if (this.AssessmentDateTo){
                            searchObject.filters.push({ Name: 'AssessmentDateTo', Value: this.AssessmentDateTo });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                            ToWarehouseId: this.customsWarehouse.Id,
                            showAll : this.showAll,
                            showAgedStock : this.showAged
                        }

                        return this.warehouseService.getBondStoreReceiptsViewModel().query(params, (result: Array<interfaces.master.BondStoreReceiptsViewModel>) => {
  
                            this.gvwBondStores.data = result;
                            if (result[0]) {
                                this.gvwBondStores.totalItems = result[0].NumRecords_IN;
                            } else {
                                this.gvwBondStores.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.gridApi) {
                                    //this.getFilters();
                                    //this.setFilters();
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            RecalculateTotals(){
                var selectedRow = <interfaces.master.BondStoreReceiptsViewModel>this.gridApi.selection.getSelectedRows()[0];     
                                
                if (selectedRow){
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'bond.stores'
                    }, () => {
                            return this.goodsDeclarationService.recalculateTotals(selectedRow.GoodsDeclarationId_IN).save((result) => {
                                this.generalService.displayMessageHandler(result);                           
                                if (!result.HasErrorMessage) {
                                        this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
             }else{
                this.generalService.displayMessage("Please select a Goods Declaration Line to Recalculate Remaining Qty.", Enum.EnumMessageType.Warning);
             }
            }

            ShowConsignment(consignmentId:number,IsInbound:boolean){
                var url = "";
                if (IsInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                }

                window.open(url, '_blank');
            }

            public gvwBondStores: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "Status_IN",
                    field: "Status_IN",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `<div style="text-align: center; padding-top: 6px;" >
                                        <div ng-if="row.entity.Status_IN === 0">
                                            <span class="fa fa-check-circle" style="color: #bcd856;"                                           
                                            ></span>
                                        </div>
                                        <div ng-if="row.entity.Status_IN === 1">
                                            <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"
                                            uib-tooltip='{{row.entity.Days_IN}} Days in the Bond Warehouse' tooltip-placement="right"
                                            ></span>
                                        </div>
                                        <div ng-if="row.entity.Status_IN === 2">
                                            <span class="fa fa-times" style="color: #cb2330;"
                                            uib-tooltip='{{row.entity.Days_IN}} Days in the Bond Warehouse' tooltip-placement="right"
                                            ></span>
                                        </div>
                                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, 
                {
                    name: "Entity_IN",
                    displayName: "Entity",
                    field: "Entity_IN",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },     
                {
                    name: "Consignment_IN",
                    displayName: "Consignment",
                    field: "Consignment_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                                <a style="cursor:pointer" ng-click="grid.appScope.bondStoresCtrl.ShowConsignment(row.entity.ConsignmentId_IN, row.entity.IsInbound_IN)"><u>{{MODEL_COL_FIELD}}</u></a>
                                        </div>`,
                }, 
{
                    name: "MRN_IN",
                    displayName: "MRN",
                    field: "MRN_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                       cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },                
                {
                    name: "AssessmentDate_IN",
                    displayName: "Assessment Date",
                    field: "AssessmentDate_IN",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    width: 140,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="AssessmentDate_IN"></gts-date>
                            </div>`,
                },
                {
                    name: "CPC_IN",
                    displayName: "CPC",
                    field: "CPC_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "Supplier_IN",
                    displayName: "Supplier",
                    field: "Supplier_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "GoodsDecLineNo_IN",
                    displayName: "#",
                    field: "GoodsDecLineNo_IN",
                    width: 40,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CommodityCode_IN",
                    displayName: "Commodity Code",
                    field: "CommodityCode_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "Origin_IN",
                    displayName: "Origin",
                    field: "Origin_IN",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "StatisticalQty_IN",
                    displayName: "Stat Qty",
                    field: "StatisticalQty_IN",
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "StatisticalUnit_IN",
                    displayName: "Unit",
                    field: "StatisticalUnit_IN",
                    width: 60,

                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CustomsValue_IN",
                    displayName: "Customs Value",
                    field: "CustomsValue_IN",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CustomsCurrency_IN",
                    displayName: "Currency",
                    field: "CustomsCurrency_IN",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CountableQty_IN",
                    displayName: "Countable Qty",
                    field: "CountableQty_IN",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "RemainingQty_IN",
                    displayName: "Remaining Qty",
                    field: "RemainingQty_IN",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                                <a style="cursor:pointer" ng-click="grid.appScope.bondStoresCtrl.DisplayLinkedOutboundTransactions_Click(row.entity.GoodsDeclarationLineId_IN)"><u>{{MODEL_COL_FIELD}}</u></a>
                                        </div>`,
                },
                {
                    name: "CountableUnit_IN",
                    displayName: "Unit",
                    field: "CountableUnit_IN",
                    width: 40,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }                  
                ]
            };


            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.filterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.sortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            sortChange(gridApi) {
                this.DoSearch();
            }
            
            filterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            ListPageChange(newPage, pageSize) {

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }


            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }


                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                if (this.AssessmentDateFrom){
                    searchObject.filters.push({ Name: 'AssessmentDateFrom', Value: this.AssessmentDateFrom });
                }

                if (this.AssessmentDateTo){
                    searchObject.filters.push({ Name: 'AssessmentDateTo', Value: this.AssessmentDateTo });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.warehouseService.getBondStoreRegisterExcel(params,this.customsWarehouse.Id,this.showAll,this.showAged).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    //this.gvwBondStoress.data = [];
                    //this.gvwBondStoress.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }
            
            
            goodsDeclarationLineId : number;
            DisplayLinkedOutboundTransactions_Click(goodsDeclarationLineId:number){
                this.goodsDeclarationLineId = goodsDeclarationLineId;
                this.DisplayLinkedOutboundTransactions();
            }
            
            DisplayLinkedOutboundTransactions(){
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="manualDeclaration">
                                <div class="gts-padding">
                                    <fieldset id="varianceAuth">
                                    <legend>
                                        Linked Outbound Transactions
                                    </legend>    
                                            <div id="gvwPreviousProcedureLines" ui-grid="declarationLineSearchCtrl.gvwPreviousProcedureLines" ui-grid-pagination ui-grid-resize-columns ui-grid-cellNav ui-grid-edit ui-grid-row-edit ui-grid-exporter ui-grid-selection class="gridFullScreen">
                                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!declarationLineSearchCtrl.gvwPreviousProcedureLines.data || declarationLineSearchCtrl.IsLoading ||declarationLineSearchCtrl.gvwPreviousProcedureLines.data.length===0">
                                                    <div class="msg" ng-show="(!declarationLineSearchCtrl.gvwPreviousProcedureLines.data || declarationLineSearchCtrl.gvwPreviousProcedureLines.data.length===0) && !declarationLineSearchCtrl.IsLoading">
                                                        <span>No Rows</span>
                                                    </div>
                                                    <div class="loadingGrid" ng-show="declarationLineSearchCtrl.IsLoading">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    </div>
                                                </div>
                                            </div>
                                                                                                                                                                                                     
                                            <div class="gts-padding pull-right">                                               
                                            <button type="button" class="btn btn-info" ng-click="declarationLineSearchCtrl.cancel()">Close</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class declarationLineSearchCtrl {

                        paginationOptions = {
                            pageNumber: 1,
                            pageSize: 25
                        };

                        showAll:boolean = false;
                        gridApi: uiGrid.IGridApi;

                        sortName: string;
                        sortDirection: string;
                        IsLoading : boolean = false;
                        selectedLine: uiGrid.IGridRow;
                        disableOkButton: boolean = true;                       
                        
                        constructor(private $scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,  private $q: ng.IQService,
                            private warehouseService: interfaces.master.IWarehouseService,
                            private generalService: interfaces.applicationcore.IGeneralService,
                            private $timeout: ng.ITimeoutService,
                            private goodsDeclarationLineId: number
                            ) {
                                this.GetBondStoreDisposalsViewModel();
                        }

                        GetBondStoreDisposalsViewModel(){
                            var searchObject: interfaces.applicationcore.ISearchObject = {
                                filters: [],
                                sorts: []
                            }

                            if (this.gridApi) {
                                var grid = this.gridApi.grid;
                                angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                    if (n.filters[0].term) {
                                        searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                    }
    
                                    if (n.sort.direction) {
                                        searchObject.sorts.push({
                                            Name: n.name,
                                            SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                            SortOrder: n.sort.priority
                                        });
                                    }
                                });
                            }
                            
                            var params = {
                                connectSearch: searchObject,
                                GoodsDeclarationLineId : this.goodsDeclarationLineId,
                                numberRecords: this.paginationOptions.pageSize,
                                pageNumber: this.paginationOptions.pageNumber,
                            }

                            return this.warehouseService.getBondStoreDisposalsViewModel().query(
                                params, (result: Array<interfaces.master.BondStoreDisposalsViewModel>) => {
                                   
                                    this.gvwPreviousProcedureLines.data = result;

                                    if (result[0]) {
                                        this.gvwPreviousProcedureLines.totalItems = result[0].NumRecords_OUT;
                                    } else {
                                        this.gvwPreviousProcedureLines.totalItems = 0;
                                    }                                
                               
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;

                        }



                        sortChange(gridApi) {
                            this.GetBondStoreDisposalsViewModel();
                        }
                        
                        filterChange(gridApi: uiGrid.IGridApi) {
                            this.GetBondStoreDisposalsViewModel();
                        }

                        
                        ListPageChange(newPage, pageSize) {

                            this.paginationOptions.pageNumber = newPage;
                            this.paginationOptions.pageSize = pageSize;

                            this.GetBondStoreDisposalsViewModel();
                        }

                        public registerGridApi(gridApi: uiGrid.IGridApi) {
                            this.gridApi = gridApi;
            
                            this.gridApi.core.on.filterChanged(this.$scope, () => { this.filterChange(gridApi) });
                            this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                                this.sortChange(gridApi);
                                if (sortColumns[0]) {
                                    this.sortName = sortColumns[0].name;
                                    this.sortDirection = sortColumns[0].sort.direction;
                                }
                                else {
                                    this.sortName = "";
                                    this.sortDirection = "";
                                }
                            });
            
            
                            this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
            
                            this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                                this.$timeout(() => {
                                    this.gridApi.selection.selectRow(newCol.row.entity);
                                });
                            });


                        }

                        public gvwPreviousProcedureLines: uiGrid.IGridOptions | any = {
                            data: [],
                            enableSorting: true,
                            enableFiltering: true,
                            useExternalFiltering: true,
                            enableCellEdit: false,
                            useExternalSorting: true,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableRowSelection: false,
                            enableFullRowSelection: false,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enablePinning: false,
                            rowEditWaitInterval: -1,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            enableVerticalScrollbar: 1,
                            useExternalPagination: true,
                            enableGridMenu: false,
                            exporterMenuCsv: false,
                            exporterMenuPdf: false,
                            exporterMenuExcel: false,
                            gridMenuShowHideColumns: false,
                            exporterFieldCallback: (grid, row, col, value) => {
                                return this.generalService.handleValue(value);
                            },
                            exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                            onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                            rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.PreviouslyLinkedCommercialInvoiceLineId>0}" ui-grid-cell></div>`,
                            columnDefs: [                                
                                {
                                    name: "Entity_OUT",
                                    displayName: "Entity",
                                    field: "Entity_OUT",
                                    width: 60,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "Consignment_OUT",
                                    displayName: "Consignment",
                                    field: "Consignment_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                            <a style="cursor:pointer" ng-click="grid.appScope.declarationLineSearchCtrl.ShowConsignment(row.entity.ConsignmentId_OUT, row.entity.IsInbound_OUT)"><u>{{MODEL_COL_FIELD}}</u></a>
                                            </div>`,
                                },     
                                {
                                    name: "MRN_OUT",
                                    displayName: "MRN",
                                    field: "MRN_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "AssessmentDate_OUT",
                                    displayName: "Assessment Date",
                                    field: "AssessmentDate_OUT",
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                    width: 140,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                            </div>`,
                                },        {
                                    name: "CPC_OUT",
                                    displayName: "CPC",
                                    field: "CPC_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "Customer_OUT",
                                    displayName: "Customer",
                                    field: "Customer_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, {
                                    name: "Customer_OUT",
                                    displayName: "Customer",
                                    field: "Customer_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "Destination_OUT",
                                    displayName: "Destination",
                                    field: "Destination_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "Declarant_OUT",
                                    displayName: "Declarant",
                                    field: "Declarant_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },    
                                {
                                    name: "GoodsDecLineNo_OUT",
                                    displayName: "#",
                                    field: "GoodsDecLineNo_OUT",
                                    width: 40,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },                                            
                                {
                                    name: "CommodityCode_OUT",
                                    displayName: "Commodity Code",
                                    field: "CommodityCode_OUT",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "CountableQty_OUT",
                                    displayName: "Countable Qty",
                                    field: "CountableQty_OUT",
                                    width: 180,
                                    cellClass: 'text-right',
                                    cellFilter: 'number: 2',
                                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                                },
                                {
                                    name: "CountableUnit_OUT",
                                    displayName: "Unit",
                                    field: "CountableUnit_OUT",
                                    width: 40,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                } ,
                                {
                                    name: "StatisticalQty_OUT",
                                    displayName: "Stat Qty",
                                    field: "StatisticalQty_OUT",
                                    cellClass: 'text-right',
                                    cellFilter: 'number: 2',
                                    width: 100,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "StatisticalUnit_OUT",
                                    displayName: "Unit",
                                    field: "StatisticalUnit_OUT",
                                    width: 60,
                
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "TransactionValue_OUT",
                                    displayName: "Transaction Value",
                                    field: "TransactionValue_OUT",
                                    width: 150,
                                    cellClass: 'text-right',
                                    cellFilter: 'number: 2',
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "TransactionCurrency_OUT",
                                    displayName: "Currency",
                                    field: "TransactionCurrency_OUT",
                                    width: 60,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "CustomsValue_OUT",
                                    displayName: "Customs Value",
                                    field: "CustomsValue_OUT",
                                    width: 150,
                                    cellClass: 'text-right',
                                    cellFilter: 'number: 2',
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "CustomsCurrency_OUT",
                                    displayName: "Currency",
                                    field: "CustomsCurrency_OUT",
                                    width: 60,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }                                                                     
                                       
                            ]                
                        }

                        ShowConsignment(consignmentId:number,IsInbound:boolean){
                            var url = "";
                            if (IsInbound){
                                url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                            }
                            else{                    
                                url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                            }
            
                            window.open(url, '_blank');
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {
                    
                            this.$uibModalInstance.close();
                        }

                                
                    },
                    controllerAs: "declarationLineSearchCtrl",
                    size: "lg",
                    resolve: {
                        goodsDeclarationLineId: () => { return this.goodsDeclarationLineId }
                       
                    }
                }).result;
            }
            
         

            
        }

         angular.module("app").controller("bondStoresCtrl", controllers.master.bondStoresCtrl);    
    }
}